@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primary-color: #7b31b2;
  --yellow: #ffc000;
  --green: #00ac3e;
  --hover-color: #f5e9ff;
  --dashboardBackground: #f5f5f5;
  --text-color: #343434;
  --inputGray: ##b0b0b0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
em,
i,
ul,
li,
a,
span,
button,
input,
label,
textarea,
select {
  font-family: "Inter", sans-serif;
  font-size: clamp(0.8rem, 1rem, 1rem);
  color: var(--text-color);
}

h1 {
  font-size: clamp(1.1rem, 2.5rem, 2.5rem);
}
h2 {
  font-size: clamp(1rem, 2rem, 2rem);
}

h6 {
  font-weight: 500;
  font-size: clamp(1rem, 1.2rem, 1.2rem);
}

.input {
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  height: 50px;
  width: 50px !important;
  font-size: 2rem;
}
.input:focus {
  border: #00ac3e solid 2px !important;
}
.input_container {
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  p {
    font-size: 0.8rem;
  }
  .mySwiper {
    height: 40vh;
  }
}

@media screen and (min-width: 2000px) {
  body {
    max-width: 1200px;
    margin: 0 auto;
  }
}

.admin-card-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.role-label {
  color: var(--primary-color);
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
}

.mb-20 {
  margin-bottom: 20px;
}

button,
.outline-button {
  background-color: var(--primary-color);
  padding: 0.6rem;
  color: white;
  display: flex;
  font-size: 0.8rem;
  outline: none;
  border: none;
  border-radius: 12px;
  align-items: center;
  gap: 0.5rem;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  justify-content: center;
}

.outline-button {
  background-color: #fff;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.mt-20 {
  margin-top: 20px;
}

.admin-wrapper-link {
  /* display: block !important; */
  /* width: 100% !important; */
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 20px 16px;
  width: 30%;
  max-width: 600px;
  min-width: 200px;
  cursor: pointer;
  text-decoration: none;
}

#react-select-33-listbox {
  /* display: flex !important; */
  /* flex-direction: column !important; */
}
